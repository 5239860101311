<template>
    <div class="single-horizontal-bar-chart">
        <div
            v-if="hasNoData"
            class="single-horizontal-bar-chart--no-data">
            <p class="single-horizontal-bar-chart--no-data-text">
                No data found
            </p>
        </div>

        <div
            v-if="!hasNoData"
            class="single-horizontal-bar-chart--legend"
            role="list">
            <div
                v-for="(item, index) in config"
                :key="item.label + item.color"
                :data-cy="getDataCy(item, 'legend')"
                role="listitem">
                <pendo-popover
                    placement="top"
                    trigger="focus hover"
                    popover-inner-class="single-horizontal-bar-chart--popover-inner"
                    @hide="onLegendPopoverHide(index)"
                    @show="onLegendPopoverShow(index)">
                    <template #default>
                        <div
                            :aria-label="getAriaLabel(item, percentages[index])"
                            class="single-horizontal-bar-chart--legend-item">
                            <div
                                class="single-horizontal-bar-chart--legend-item-icon"
                                :style="getIconStyle(item)">
                                <pendo-icon
                                    v-if="item.icon"
                                    :type="item.icon"
                                    size="14" />
                            </div>

                            <div class="single-horizontal-bar-chart--legend-item-label">
                                <div class="single-horizontal-bar-chart--legend-item-label-value">
                                    {{ percentages[index] }}%
                                </div>
                                <div class="single-horizontal-bar-chart--legend-item-label-name">
                                    {{ item.label }}
                                </div>
                                <div class="single-horizontal-bar-chart--legend-item-label-description">
                                    {{ item.description }}
                                </div>
                            </div>
                        </div>
                    </template>
                </pendo-popover>
            </div>
        </div>

        <div
            v-if="!hasNoData"
            class="single-horizontal-bar-chart--bars">
            <div
                v-for="(item, index) in config"
                :key="item.label + item.color"
                class="single-horizontal-bar-chart--bars-item"
                :data-cy="getDataCy(item, 'bar')"
                :style="getBarStyle(item, index)"
                role="presentation" />
        </div>
    </div>
</template>

<script>
import { PendoPopover, PendoIcon } from '@pendo/components';

export default {
    name: 'SingleHorizontalBarChart',
    components: {
        PendoPopover,
        PendoIcon
    },
    props: {
        config: {
            type: Array[Object],
            required: true
        },
        percentagePrecision: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            highlightedItem: null
        };
    },
    computed: {
        countTotal () {
            return this.config.reduce((countTotal, { count }) => {
                return countTotal + count;
            }, 0);
        },
        hasNoData () {
            return this.countTotal === 0;
        },
        percentages () {
            // 1. Round our percentages.
            const power = 10 ** (this.percentagePrecision + 2);
            const divisor = 10 ** this.percentagePrecision;
            let percentageTotal = 0;
            const percentages = this.config.map(({ count }, index) => {
                const percentage = Math.floor((count / this.countTotal) * power) / divisor;

                percentageTotal += percentage;

                return {
                    count,
                    index, // Need to keep track of index for later to return the array back to it's original order.
                    percentage
                };
            });
            // 2. Get the offset.
            const percentageOffset = 100 - percentageTotal;

            // 3. Sort the array by the delta of the rounded percentage to the actual value.
            percentages.sort((a, b) => {
                const aDelta = a.percentage - (a.count / this.countTotal) * 100;
                const bDelta = b.percentage - (b.count / this.countTotal) * 100;

                return aDelta - bDelta;
            });

            // 4. Adjust the percentages so they always equal out to 100%.
            const fixedPercentages = percentages.map((item, index) => {
                return {
                    index: item.index,
                    percentage: (item.percentage + (index < percentageOffset ? 0.1 : 0)).toFixed(
                        this.percentagePrecision
                    )
                };
            });

            // 5. Sort the array back to the original order.
            fixedPercentages.sort((a, b) => {
                return a.index - b.index;
            });

            // 6. Get just the percentages.
            return fixedPercentages.map((item) => item.percentage);
        }
    },
    methods: {
        getAriaLabel (item, percentage) {
            const ariaLabel = item.description || '';

            return `${percentage}% ${item.label}. ${ariaLabel}`.trimEnd();
        },
        getBarStyle (item, index) {
            const percentage = this.percentages[index];

            let opacity = 1;
            if (this.highlightedItem !== null && this.highlightedItem !== index) {
                opacity = 0.2;
            }

            return {
                background: item.color,
                opacity,
                width: `${percentage}%`
            };
        },
        getDataCy (item, context) {
            const dataCy = item.cypressID || 'single-horizontal-bar-chart';

            return `${dataCy}-${context}`;
        },
        getIconStyle (item) {
            return {
                background: item.color
            };
        },
        onLegendPopoverHide (index) {
            if (this.highlightedItem === index) {
                this.highlightedItem = null;
            }
        },
        onLegendPopoverShow (index) {
            this.highlightedItem = index;
        }
    }
};
</script>

<style lang="scss">
.single-horizontal-bar-chart {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &--no-data {
        align-items: center;
        display: flex;
        height: 168px;
        width: 100%;

        &-text {
            margin: 0;
            text-align: center;
            width: 100%;
        }
    }

    &--popover-inner {
        padding: 0 !important; // stylelint-disable-line declaration-no-important
    }

    &--legend {
        display: flex;
        justify-content: space-evenly;
        margin: 14px 0;
        width: 100%;

        .v-popover .trigger.focus-visible {
            outline: none;

            .single-horizontal-bar-chart--legend-item {
                border: 2px solid $pink-primary;
                border-radius: 5px;
                padding: 6px;
            }
        }

        &-item {
            align-items: center;
            display: flex;
            flex: 0 1 auto;
            padding: 8px;

            &-icon {
                align-items: center;
                border-radius: 50%;
                color: $white;
                display: flex;
                flex: 0 0 30px;
                height: 30px;
                justify-content: center;
                margin-right: 10px;
                width: 30px;
            }

            &-label {
                flex: 1 1 auto;

                &-value {
                    font-size: 28px;
                    font-weight: 600;
                    line-height: normal;
                    text-align: center;
                }

                &-name {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: normal;
                    text-align: center;
                    white-space: nowrap;
                }

                &-description {
                    margin-top: 4px;
                    color: $gray-lighter-2;
                }
            }
        }
    }

    &--bars {
        display: flex;
        height: 53px;
        width: 100%;

        &-item {
            box-sizing: border-box;
            border-right: 1px solid white;
            transition: opacity 250ms ease-in;
            transition: width 500ms ease-in;

            .v-popover,
            .trigger {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
